@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@400;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'react-phone-number-input/style.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.PhoneInputInput{
    border-radius: 0.375rem;
    border-width: 1px;
    border-color: #dadde3;
  }
  [type='tel']:focus{
    border-width: 1px;
    border-color: #b68a35; 
    --tw-ring-color:#b68a35
  }